<template>
  <v-dialog :value="value" persistent max-width="600px">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title>
          {{ $t("hr.message.send_message") }}
          <v-spacer />
          <v-btn depressed icon @click="onCancelClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="12">
              <validation-provider :name="$t('hr.message.receivers')" rules="required" v-slot="{ errors }">
                <employee-picker
                  v-model="message.receivers"
                  :multiple="true"
                  :label="$t('hr.message.receivers')"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <v-label>{{ $t("hr.message.message_type") }}</v-label>
              <v-radio-group row mandatory v-model="message.messageType">
                <v-radio
                  :key="type.value"
                  v-for="type in $helpers.fromEnumToArray(NOTIFICATIONS.TYPES)"
                  :value="type.value"
                  color="primary"
                  :label="$t(`hr.message.message_type_enum.${type.text}`)"
                />
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-if="message.messageType !== NOTIFICATIONS.TYPES.SMS">
              <validation-provider :name="$t('hr.message.title')" rules="required" v-slot="{ errors }">
                <v-text-field
                  v-model="message.title"
                  color="tertiary"
                  :label="$t('hr.message.title')"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                :name="$t('hr.message.body')"
                :rules="{ required: true, max: NOTIFICATIONS.COUNTS[message.messageType] }"
                v-slot="{ errors }"
              >
                <v-textarea
                  v-model="message.body"
                  rows="5"
                  color="tertiary"
                  :counter="NOTIFICATIONS.COUNTS[message.messageType]"
                  :label="$t('hr.message.body')"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <v-label>{{ $t("hr.message.importance") }}</v-label>
              <v-radio-group row mandatory v-model="message.importance">
                <v-radio
                  :key="type.value"
                  v-for="type in $helpers.fromEnumToArray(NOTIFICATIONS.IMPORTANCE)"
                  :value="type.value"
                  color="primary"
                  :label="$t(`hr.message.message_importance_enum.${type.text}`)"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="dialog-footer">
          <v-spacer></v-spacer>
          <v-btn :loading="loading" depressed @click.stop="handleSubmit(onSaveClick)">{{ $t("buttons.send") }}</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-30 17:02:46
   * @modify date 2020-12-30 17:02:46
   * @desc SendMessageDialog
   */
  import { NOTIFICATIONS } from "@/helpers/enums";
  import Message from "../model/Message";

  export default {
    name: "SendMessageDialog",
    components: {
      EmployeePicker: () => import("@/components/selectpickers/EmployeePicker")
    },
    props: {
      value: Boolean
    },
    data: vm => ({
      NOTIFICATIONS,
      loading: false,
      message: new Message(),
      sender: "b479fea7-9fa6-4c25-958b-e6793acf9c2f",
      receivers: [
        "b479fea7-9fa6-4c25-958b-e6793acf9c2f",
        "1dab97be-bdfc-4f39-99da-9773e67bfb80",
        "835090bc-e0bc-485e-b6fb-bdb5ed6d7939",
        "8629fa42-b182-40bd-b471-3e44d6fa9d28",
        "204cc152-3997-4278-b4ed-649a16e4cfd2",
        "0941b918-8c5b-463c-ad82-13c184cd39ac"
      ]
    }),
    methods: {
      onCancelClick() {
        this.message = new Message();
        this.$refs.observer.reset();
        this.$emit("input", false);
      },
      onSaveClick() {
        this.loading = true;
        this.$api.messageService
          .save(this.message)
          .then(({ data }) => {
            if (!data.error) {
              this.$helpers.showNotification(
                this.$tc("hr.message.success_message", this.message.receivers.length, [
                  this.message.receivers.length,
                  this.$t(`hr.message.message_type_enum.${this.message.messageType}`)
                ]),
                "success",
                true
              );
              this.$emit("message:send");
              this.onCancelClick();
            }
          })
          .catch(e => this.$helpers.showNotification(e.message, "error"))
          .then(() => (this.loading = false));
      }
    }
  };
</script>

<style></style>
